import axios from "axios";
// import { getTokenSilently, getAuth0Client } from "../../auth01";
import store from "../store";
import activeOrgActions from "../slices/activeorg.slice";
import criticalActions from "../slices/critical.slice";
import identityActions from "../slices/identity.slice";
import orgActions from "../slices/org.slice";
import orgtypesActions from "../slices/orgtypes.slice";
import permissionsActions from "../slices/permissions.slice";
import { B, make_toast } from "../../helpers";
import { CatchedWebError } from "../../configs";
import { deviceActions, infraListActions, oneInfraActions, devicesDebugActions, notificationAction } from "../slices";
import { getToken, clearToken, refreshToken } from "../../authCustom";
import { make_custom_toast } from "../../helpers/toasts";

function GET_DATA(url, controller, token, session_id = null) {
  let headers = {
    Authorization: `Bearer ${token}`
  };
  if (session_id) {
    headers["x-api-key"] = `${session_id}`;
  }
  return new Promise((resolve, reject) => {
    axios.get(url, {
      signal: controller.signal,
      headers: headers
    }).then((response) => {
      resolve(response);
    }).catch(async e => {
      if (e?.response?.status === 401) {
        await refreshToken();
        window.location.reload();
      }
      else
        reject();
    })
  })
}

function initSocket(token) {
  let socket = window.io(process.env.REACT_APP_SOCKET, {
    auth: {
      Authorization: `Bearer ${token}`
    },
    transports: ["websocket"]
  });

  socket.on("message", (message) => {

    const socket_msg = JSON.parse(message);
    if (socket_msg?.category === "BULK_IMPORT") {
      const UUID = store.getState().identity.meta.UUID;
      if (socket_msg.data.bulkActionId === UUID) {
        store.dispatch(identityActions.setImportNotification(socket_msg.data));
      }
    }
    else if (socket_msg?.category === "FW_UPGRADE_ALL") {
      store.dispatch(identityActions.setUpdateDashboardMessage(socket_msg.data));
    }
    // else if (socket_msg?.category === "DEVICE_CONNECT") {
    //   const oid = store.getState().activeOrg.data.orgId;
    //   console.log(oid);
    //   if (oid && oid === socket_msg?.orgId) {
    //     store.dispatch(deviceActions.addSocketData({
    //       mac: socket_msg?.data.client,
    //       connectedTo: socket_msg?.data.ssid,
    //       serial: socket_msg?.data.serial,
    //       venueId: socket_msg?.data.venueId,
    //       venueName: socket_msg?.data.venueName,
    //       infraId: socket_msg?.data.infraItemId,
    //       infraName: socket_msg?.data.infraName,
    //       deviceName: socket_msg?.data.deviceName,
    //       channel: socket_msg?.data.channel,
    //       band: socket_msg?.data.band
    //     }));
    //     store.dispatch(disconnectedActions.removeSocketData2(socket_msg?.data.client));
    //   }
    // }
    // else if (socket_msg?.category === "DEVICE_DISCONNECT") {
    //   const oid = store.getState().activeOrg.data.orgId;
    //   console.log(oid);
    //   if (oid && oid === socket_msg?.orgId) {
    //     store.dispatch(deviceActions.removeSocketData(socket_msg?.data.client));
    //     store.dispatch(disconnectedActions.addSocketData2({
    //       mac: socket_msg?.data.client,
    //       connectedTo: socket_msg?.data.ssid,
    //       serial: socket_msg?.data.serial,
    //       venueId: socket_msg?.data.venueId,
    //       venueName: socket_msg?.data.venueName,
    //       infraId: socket_msg?.data.infraItemId,
    //       infraName: socket_msg?.data.infraName,
    //       deviceName: socket_msg?.data.deviceName,
    //       band: socket_msg?.data.band
    //     }))
    //   }
    // }
    // else if (socket_msg?.category === "DEVICE_DISCONNECT_LIST") {
    //   const oid = store.getState().activeOrg.data.orgId;
    //   console.log(oid);
    //   if (oid && oid === socket_msg?.orgId) {
    //     const removedDevices = socket_msg?.data ?? [];
    //     for (const device of removedDevices) {
    //       store.dispatch(deviceActions.removeSocketData(device.client));
    //       store.dispatch(disconnectedActions.addSocketData2({
    //         mac: device?.client,
    //         connectedTo: device?.ssid,
    //         serial: device?.serial,
    //         venueId: device?.venueId,
    //         venueName: device?.venueName,
    //         infraId: device?.infraItemId,
    //         infraName: device?.infraName,
    //         deviceName: device?.deviceName
    //       }))
    //     }
    //   }
    // }
    else if (socket_msg?.category === "AP_STATUS") {
      store.dispatch(infraListActions.setStatus({ infraItemId: socket_msg?.data.infraItemId, status: socket_msg?.data.status.toUpperCase() }));
      if (store.getState().oneInfra.data.infraItemId === socket_msg?.data.infraItemId) {
        store.dispatch(oneInfraActions.setStatus(socket_msg?.data.status === "online"));
      }
    }
    else if (socket_msg?.category === "SUPPORT_REPLY") {
      make_toast('success', 'One Support Ticket is Updated')

      // --- Below is the code for Updating Bubble Notification Number
      // store.dispatch(notificationAction.setTicketNotification(socket_msg?.data?.count));
    }
    else if (socket_msg?.category === "ALARM_NOTIFICATION") {
      const activeOrgId = store.getState().activeOrg.data.orgId;
      // check if activeOrgId is maching with the orgId in socket
      if (activeOrgId === socket_msg?.data?.orgId) {
        store.dispatch(notificationAction.setInfraOfflineAlarmCount(socket_msg?.data?.count));
      }
    }
    else if (socket_msg?.category === "UE_DEBUG") {
      if (socket_msg?.data?.status === "error") {
        socket_msg?.data?.message && make_toast("error", socket_msg?.data?.message);
        store.dispatch(devicesDebugActions.setDevice({ status: "N/A", ueId: (socket_msg?.data?.macAddress + socket_msg?.data?.macAddress) }));
      }
      store.dispatch(devicesDebugActions.setDevice({ status: socket_msg?.data?.status, ueId: (socket_msg?.data?.macAddress + socket_msg?.data?.macAddress) }));
    }
    else {
      //console.log("SOCKET DATA RECEIVED");
      store.dispatch(identityActions.setSocketData(socket_msg));
    }
  });

  return socket;
}

async function CriticalSetup() {
  // const auth0Client = await getAuth0Client();
  // const {getToken, logout} = useAuth();
  store.dispatch(criticalActions.setLoading(true));
  store.dispatch(identityActions.setLoading(true));
  store.dispatch(permissionsActions.setLoading(true));
  store.dispatch(orgActions.setLoading(true));
  store.dispatch(activeOrgActions.setLoading(true));
  store.dispatch(orgtypesActions.setLoading(true));
  // store.dispatch(infraTypesActions.setLoading(true));  This is Moved to ActiveOrgMaker
  let afterPromise = null;  // needs to exist for compatibility with older criticalSetup implementation

  try {
    const controller = new AbortController();

    // TOKEN
    const token = await getToken();
    // const token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InVaREdzR1VRWEdaWUx1R0ktd3RnYyJ9.eyJodHRwOi8vc2hhc3RhLWNsb3VkLm5ldC9vcmdJZCI6NzgxLCJodHRwOi8vc2hhc3RhLWNsb3VkLm5ldC9hdXRoT3JnSWQiOiJvcmdfT2k5V1VGYVVGdndmUW5ReSIsImh0dHA6Ly9zaGFzdGEtY2xvdWQubmV0L3Blcm1pc3Npb25zIjpbIm1hbmFnZTpmaXJtd2FyZSIsIm1hbmFnZTpvcmdhbml6YXRpb24iLCJtYW5hZ2U6dmVudWUiLCJ2aWV3Om9yZ2FuaXphdGlvbiIsInZpZXc6dmVudWUiXSwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5kZXYuc2hhc3RhY2xvdWQuY29tLyIsInN1YiI6ImF1dGgwfDYzYWQ5NDU4MTFhZDVmNjFhZmZmZTNkNyIsImF1ZCI6WyJodHRwOi8vc2hhc3RhZGV2LmNvbSIsImh0dHBzOi8vZGV2LXliMWprMWs1LnVzLmF1dGgwLmNvbS91c2VyaW5mbyJdLCJpYXQiOjE2ODM3MTM2NDUsImV4cCI6MTY4NDU3NzY0NSwiYXpwIjoidlNIdjlIVkJIZ1RUWGdHWXNDZ2JHejhFWFA0VzFaMTIiLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwicGVybWlzc2lvbnMiOltdfQ.D637CQ2_Ip_q2GhSJTLg2JwG6-EAD10QK092yzWQrwDNOPBkOurAYbPgaM4xYWGGutQSzTvON3xHwvwPu0TazP5VOn5267M-GcBaF8jC8I96XwYDGW06zWKXcW02JPGdASvHWQ87ufINniCRrDCw_VEwjZQCA0zFOp8qW5WynRUgVTCRl8cbTq0-e98xpv8n2MXaF-M__gNf_57-cSsdkFWwrAYg-2fDBVsYUTU6Fduouv-ep03I8Un3lAM-3OCfz2i-Hzi_r0iJ7YQoRVzztuJm-TFT-Cld9rp7XTxAWKytPAuk63_6eCZpo7u-AWMA7YHx3Vzm_bMgLvNdoJUURA"
    store.dispatch(identityActions.setToken(token));

    // SOCKET
    let socket = initSocket(token);

    socket.on("connect_error", (err) => {
      //console.log(err);
      // socket = initSocket(token);
    });

    let identity = null;

    try {
      // IDENTITY
      identity = await GET_DATA(B("identity"), controller, token);
      store.dispatch(identityActions.setIdentity(identity.data));
    } catch (e) {
      let x = CatchedWebError(e);
      if (x.browserCode == 401)
        clearToken();
    }

    // :Check_for_blocked
    if (store.getState().identity.blocked) {
      store.dispatch(criticalActions.setStatus("BLOCKED"));
      store.dispatch(criticalActions.setLoading(false));
      return { controller, afterPromise };
    }

    // PERMISSIONS
    const identityId = store.getState().identity.data.identityId;
    const isInvited = store.getState().identity.data.isInvited;

    if (!isInvited) {
      const permissions = await GET_DATA(B(`identity/${identityId}/permissions`), controller, token);
      store.dispatch(permissionsActions.setPermissions(permissions.data));

      // ORGANIZATION TYPES
      const orgTypes = await GET_DATA(B("/organization/orgtype"), controller, token);
      store.dispatch(orgtypesActions.setOrgTypes(orgTypes.data));

      //  ORGANIZATION
      const organization = await GET_DATA(B("organization"), controller, token);
      store.dispatch(orgActions.setOrg(organization.data));
      //store.dispatch(activeOrgActions.setActiveOrg(organization.data));

      // ORGANIZATION ROLES
      const orgRoles = await GET_DATA(B(`/organization/${organization.data.orgId}/roles?offset=0&limit=1000`), controller, token);
      store.dispatch(orgActions.setRoles(orgRoles.data));
      //store.dispatch(activeOrgActions.setRoles(orgRoles.data));

      // // ORGANIZATION LOGO
      // const organizationLogo = await GET_DATA(B(`organization/${organization.data.orgId}/logo?purpose=get`), controller, token);
      // store.dispatch(activeOrgActions.setActiveOrgLogo(organizationLogo.data));


      // This is Moved to ActiveOrgMaker
      // const infraTypes = await GET_DATA(B(`/infrastructure/infraType?orgId=${organization.data.orgId}`), controller, token);
      // store.dispatch(infraTypesActions.setInfraTypes(infraTypes.data));
    }

    store.dispatch(criticalActions.setStatus("OK"));
    return { controller, afterPromise };

  } catch (err) {
    let x = new CatchedWebError(err);
    store.dispatch(orgtypesActions.setError(x.message));
    store.dispatch(activeOrgActions.setError(x.message));
    store.dispatch(orgActions.setError(x.message));
    store.dispatch(permissionsActions.setError(x.message));
    store.dispatch(identityActions.setError(x.message));
    store.dispatch(criticalActions.setError(x.message));
    // store.dispatch(infraTypesActions.setError(x.message)); this is moved to ActiveOrgMaker
    store.dispatch(criticalActions.setStatus("ERROR"));

  } finally {
    store.dispatch(orgtypesActions.setLoading(false));
    store.dispatch(activeOrgActions.setLoading(false));
    store.dispatch(orgActions.setLoading(false));
    store.dispatch(permissionsActions.setLoading(false));
    store.dispatch(identityActions.setLoading(false));
    store.dispatch(criticalActions.setLoading(false));
    // store.dispatch(infraTypesActions.setLoading(false));
  }
}

export default CriticalSetup;